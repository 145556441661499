import './App.css';
import { Icon } from '@iconify/react';

function App() {
  return (
    <div className="App">
      <img src="https://www.cove.sa/assets/images/image12.png?v=325fd842" alt="" />
      <div class="sub-heading">
        <h1>
          ،مقهى كوف، أول قهوة مختصة في جازان
        يمثل صنع القهوة المختصة و إنتاج الحلويات
        ،بأعلى جودة، نشتهر بألذّ كيكة عسل
        والفطور الصحي اللذيذ  
        </h1>
      </div>
      <div className="buttons">
        <button>1</button>
        <button>2</button>
        <button>3</button>
        <button>4</button>
        <button>5</button>
        <button>6</button>
        <button>7</button>
      </div>
      <div className="logo">
        <Icon icon="akar-icons:twitter-fill" />
        <Icon icon="akar-icons:snapchat-fill" />
        <Icon icon="akar-icons:tiktok-fill" />
        <Icon icon="akar-icons:instagram-fill" />
        <Icon icon="akar-icons:youtube-fill" />
      </div>
      <p>جميع الحقوق محفوظة © لشركة مقهى كوف لتقديم الوجبات</p>
    </div>
  );
}

export default App;
